/* eslint-disable prettier/prettier */
import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import JwtService from "@/core/services/jwt.service";
import { showToast } from "../helper/toast.helper";
//import Router from "vue-router";
/**
 * Service to call HTTP request via Axios
 */
var checkAuth = false;
function checkAuthorization() {
  if (checkAuth == false) {
    checkAuth = true;
    window.localStorage.clear();
    window.location.href = location.origin + "/login";
    setTimeout(() => {
      checkAuth = false;
    }, 2000);
  }
}
const ApiService = {
  init() {
    Vue.use(VueAxios, axios);
    // Vue.axios.defaults.baseURL = "http://172.22.22.63/v1/";
    // Vue.axios.defaults.baseURL = "http://api.sisdoc.rumahit.co.id/v1/";
    // Vue.axios.defaults.baseURL = "http://localhost/sisdoc_fitrah/public/v1/";
    Vue.axios.defaults.baseURL = "https://sisdoc-api.rumahitbdg.net/v1/";
    // Vue.axios.defaults.baseURL = process.env.VUE_APP_BASE_URL;
    Vue.axios.interceptors.response.use(
      function(response) {
        return response;
      },
      function(error) {
        if (error.response.status === 401) {
          checkAuthorization();
        }
        return Promise.reject(error);
      }
    );
  },
  /**
   * Set the default HTTP request headers
   */
  setHeader() {
    Vue.axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${JwtService.getToken()}`;
  },

  setCors() {
    Vue.axios.defaults.headers.common["X-Requested-With"] = "localhost:8080";
  },

  setHeaderWithContent() {
    Vue.axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${JwtService.getToken()}`;
    Vue.axios.defaults.headers.common["Content-Type"] = "application/json";
  },

  setHeaderMultiPlatform() {
    Vue.axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${JwtService.getToken()}`;
    Vue.axios.defaults.headers.common["Content-Type"] = "multipart/form-data";
  },

  setHeaderPdf() {
    Vue.axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${JwtService.getToken()}`;
    Vue.axios.defaults.headers.common["Content-Type"] = "application/pdf";
  },

  setResponseBlob() {
    Vue.axios.defaults.headers.responseType = "blob";
  },

  query(resource, params) {
    return axios.get(resource, params).catch((error) => {
      if (error.response.data.message != undefined)
        showToast("Failed", error.response.data.message, "danger");
      throw new Error(`[KT] ApiService ${error}`);
    });
  },

  /**
   * Send the GET HTTP request
   * @param resource
   * @param slug
   * @returns {*}
   */
  get(resource, slug = "") {
    return Vue.axios.get(`${resource}/${slug}`).catch((error) => {
      throw new Error(`[KT] ApiService ${error}`);
    });
  },

  /**
   * Send the GET HTTP request
   * @param resource
   * @param slug
   * @returns {*}
   */
  getDataD(resource) {
    return Vue.axios.get(`${resource}/design/jobdesk`).catch((error) => {
      throw new Error(`[KT] ApiService ${error}`);
    });
  },

  /**
   * Send the GET HTTP request
   * @param resource
   * @param slug
   * @returns {*}
   */
  getDesign(resource) {
    this.setHeader();
    return Vue.axios.get(`${resource}/type`).catch((error) => {
      throw new Error(`[KT] ApiService ${error}`);
    });
  },

  /**
   * Set the POST HTTP request
   * @param resource
   * @param params
   * @returns {*}
   */
  post(resource, params) {
    return Vue.axios.post(`${resource}`, params);
  },

  postDownload(resource, params) {
    let postConfig = {
      headers: {
        "X-Requested-With": "XMLHttpRequest",
      },
      responseType: "blob",
    };

    return Vue.axios.post(`${resource}`, params, postConfig);
  },

  /**
   * Send the UPDATE HTTP request
   * @param resource
   * @param slug
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  update(resource, slug, params) {
    return Vue.axios.put(`${resource}/${slug}`, params);
  },

  /**
   * Send the PUT HTTP request
   * @param resource
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  put(resource, params) {
    return Vue.axios.put(`${resource}`, params);
  },

  /**
   * Send the DELETE HTTP request
   * @param resource
   * @param params
   * @returns {*}
   */
  delete(resource, params = {}) {
    return Vue.axios.delete(`${resource}`, params);
  },

  /**
   * Send the GET HTTP request
   * @param resource
   * @param slug
   * @returns {*}
   */
  getCustom(resource, slug = "") {
    return Vue.axios.get(`${resource}/${slug}`);
  },

  /**
   * @param url
   * @param form
   * @param contentType
   * @param username
   * @param password
   * @param port
   * @param ftpHost
   * @param ftpType
   * @returns { Promise<AxiosResponse<any>> }
   */
  uploadToFtp(
    url,
    form,
    contentType,
    username,
    password,
    port,
    ftpHost,
    ftpType
  ) {
    return Vue.axios.post(url, form, {
      headers: {
        username,
        password,
        port,
        "ftp-host": ftpHost,
        "ftp-type": ftpType,
      },
    });
  },
};

export default ApiService;
